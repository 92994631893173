import * as React from "react"
import { Link, HeadFC, PageProps } from "gatsby"
import { Layout } from "layouts/layout";
import { Button, Container, Grid } from "@mui/material";
import { SectionHeader } from "components/layout/section-header";

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage: React.FC<PageProps> = () => {
  return (
      <Layout>
          <Container maxWidth={'lg'}>
            <Grid container justifyContent={"center"} spacing={4}>
              <Grid item xs={12}>
                <SectionHeader 
                    title={"Well, this is weird..."}
                    description={"We can't seem to find what you're looking for."}
                    gutterTop
                />
              </Grid>
              <Grid item>
                <Button className={"animated-gradient"} component={Link} to={"/"}>
                  Back to Civilisation
                </Button>
              </Grid>
            </Grid>
          </Container>
      </Layout>
  )
}

export default NotFoundPage

export const Head: HeadFC = () => <title>Not found</title>
